import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Main from "./containers/Main";
import Home from "./containers/Home";
import About from "./containers/About";
import Partner from "./containers/partner";
import Contect from "./containers/contact";
import Service from "./containers/Service";
import Introduce from "./containers/Introduce";
import History from "./containers/History";
import Award from "./containers/Award";
import Partnership from "./containers/Partnership";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/contect" element={<Contect />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/Main" element={<Main />} />
        <Route path="/Introduce" element={<Introduce />} />
        <Route path="/History" element={<History />} />
        <Route path="/Award" element={<Award />} />
        <Route path="/Partnership" element={<Partnership />} />
        <Route path="/" exact element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
