import React from "react";
import "./Footer.css";
import logo from "../assets/LOGO.png";
import discord from "../assets/phone-ico.png";

function Footer() {
  return (
    <div className="footer">
      <div className="content-components">
        <div className="content-inner">
      <div className="first">
        <div className="logoCont">
          <div className="footerLogo">
          <div className="logoName">DEHUV</div>
            <div className="address">사업자등록번호: 291-39-00208<br></br>
            대표: 천정훈 | 주소: 경기도 고양시 일산동구 동국로 32, 산학협력관 739호<br></br>
            통신판매업신고번호: 제 2017-고양일산동-1604호
            </div>
          </div>
        </div>
        <div className="footerName">
          <div className="contact">CONTACT</div>
          <div className="discord">
            1800-5528
          </div>
          <div className="email">dehuv@repickus.com</div>
        </div>
      </div>
      <hr />
      <div className="secondfooter">
      Copyright 2016 DEHUV. All Rights Reserved.
      </div>
    </div>
    </div>
    </div>
  );
}

export default Footer;
