import React, { useState, useEffect, useRef } from "react";
import AOS from "aos";
import "./style.css";
import "aos/dist/aos.css";
import phone from "../assets/phone.png";
import pickusvideo from "../assets/pickus_video.mp4";
import ai from "../assets/ai.mp4";
import estimate01 from "../assets/estimate-01.mp4";
import estimate02 from "../assets/estimate-02.mp4";
import estimatemobile from "../assets/estimate-mobile.mp4";
import match01 from "../assets/match-01.mp4";
import match02 from "../assets/match-02.mp4";
import matchmobile from "../assets/match-mobile.mp4";
import shop01 from "../assets/shop-01.png";
import shop02 from "../assets/shop-02.png";
import shop03 from "../assets/shop-03.png";
import shop04 from "../assets/shop-04.png";
import shop05 from "../assets/shop-05.png";
import shop06 from "../assets/shop-06.png";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import { useMediaQuery } from 'react-responsive'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
function Service() {
  useEffect(() => {
    AOS.init();
  })
  SwiperCore.use([ EffectFade, Autoplay, Pagination, Navigation ])
  const isDesktopOrMobile = useMediaQuery({query: '(max-width:1200px)'});
  const dataAos = 'fade-up';
  const dataAosDuration = '700';
  const dataAosOffset = '100';
  const sectionOne = useRef(null);
  const sectionTwo = useRef(null);
  const sectionThree = useRef(null);
  const sectionFour = useRef(null);
  const scrollToSection = (
      elementRef: React.MutableRefObject<HTMLButtonElement | null>,
    ) => {
      if (elementRef.current !== null) {
        const offset = elementRef.current.offsetTop;
        window.scrollTo({
          top: offset - 70,
          behavior: 'smooth',
        });
      }
    };
  return (
    <div id="company" className="service">
      <section className="title">
        <div className="center">
          <h2 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>중고 가전/가구 시장의 스마트한 재해석</h2>
          <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>웹, 앱을 통한 지역 제한 없는 고객과 서비스정보의 빠른 유통</p>
          <ul className="companyNav">
            <li>서비스</li>
            <li className="on" onClick={() => scrollToSection(sectionOne)}>중고판매</li>
            <li className="" onClick={() => scrollToSection(sectionTwo)}>파트너샵</li>
            <li className="" onClick={() => scrollToSection(sectionThree)}>중고구매</li>
            <li className="" onClick={() => scrollToSection(sectionFour)}>AI시세조회</li>
          </ul>
        </div>
      </section>
      <section className="info-app-pickus" id="about1" ref={sectionOne}>
        <div className="text">
          <h3 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={500}>중고판매</h3>
          <h4 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={700}>언제 어디서든 중고가전 ∙ 가구를 <br></br>빠르게 처분할 수 있어요</h4>
          <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={900}>판매할 상품의 사진을 찍고 피커스의 간편한 견적 신청 프로세스를 통해 전국 재활용 센터의 견적을 받아 비교하실 수 있어요.</p>
          <a href="https://repickus.com/estimate/single" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={1200}>서비스 이용하러가기</a>
        </div>
        {isDesktopOrMobile !== true ?
          <div className="phoneWrap">
            <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={1500}>
              <img src={phone}></img>
              <video autoPlay muted playsInline><source src={estimate01}></source></video>
            </div>
            <div className="text" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <h4 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>전국 재활용 센터의 견적 비교</h4>
              <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>제품의 상태와 연식에 따른 전국 재활용 센터의 견적을 비교하고 마음에 드는 견적을 선택하여 바로 처분하실 수 있어요!</p>
            </div>
            <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <img src={phone}></img>
              <video autoPlay muted playsInline ><source src={estimate02}></source></video>
            </div>
          </div>
        : 
            <div className="phoneWrap">
            <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={1500}>
              <img src={phone}></img>
              <video autoPlay muted playsInline><source src={estimatemobile}></source></video>
            </div>
          </div>
        }
      </section>
      <section className="info-app-pickus" id="about2" ref={sectionTwo}>
        <h3 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>파트너샵</h3>
        <a href="https://repickus.com/shop/" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>서비스 이용하러가기</a>
        <h4 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>여기저기 복잡한 비교는 이제 그만<br></br>한 번에 다양한 상품을 비교해요!</h4>
        {isDesktopOrMobile !== true ?
        <Swiper
          slidesPerView={3.5}
          spaceBetween={30}
          loop={true}
          className="slideBox"
          id="slide"
        >
          <SwiperSlide className="one">
          <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop01}></img></div>
          </SwiperSlide>
          <SwiperSlide className="two">
          <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop02}></img></div>
          </SwiperSlide>
          <SwiperSlide className="three">
          <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop03}></img></div>
          </SwiperSlide>
          <SwiperSlide className="three">
          <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop04}></img></div>
          </SwiperSlide>
          <SwiperSlide className="three">
          <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop05}></img></div>
          </SwiperSlide>
          <SwiperSlide className="three">
          <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop06}></img></div>
          </SwiperSlide>
        </Swiper>
        : 
        <Swiper
        slidesPerView={1.2}
        spaceBetween={0}
        loop={true}
        className="slideBox"
        id="slide"
      >
        <SwiperSlide className="one">
        <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop01}></img></div>
        </SwiperSlide>
        <SwiperSlide className="two">
        <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop02}></img></div>
        </SwiperSlide>
        <SwiperSlide className="three">
        <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop03}></img></div>
        </SwiperSlide>
        <SwiperSlide className="three">
        <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop04}></img></div>
        </SwiperSlide>
        <SwiperSlide className="three">
        <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop05}></img></div>
        </SwiperSlide>
        <SwiperSlide className="three">
        <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop06}></img></div>
        </SwiperSlide>
      </Swiper>
        }
      </section>
      <section className="info-app-pickus" id="about1" ref={sectionThree}>
        <div className="text">
          <h3 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>중고구매</h3>
          <h4 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>원하는 물품을 한 번에 등록,<br></br>빠르고 쉽게 한방에 구매</h4>
          <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={500}>구매하고 싶은 상품에 대한 정보를 입력하면 내가 원하던 상품으로 전국 재활용 센터의 견적을 받아 비교하실 수 있어요.</p>
          <a href="https://repickus.com/match" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={700}>서비스 이용하러가기</a>
        </div>
        {isDesktopOrMobile !== true ?
          <div className="phoneWrap">
            <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <img src={phone}></img>
              <video autoPlay muted playsInline><source src={match01}></source></video>
            </div>
            <div className="text" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <h4 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>내 예산에 맞춰 구매</h4>
              <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>전국의 재활용 센터에서 알아서 견적을 넣어주니까 비교하고 선택만 하면 끝!</p>
            </div>
            <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <img src={phone}></img>
              <video autoPlay muted playsInline><source src={match02}></source></video>
            </div>
          </div>
        : 
          <div className="phoneWrap">
            <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <img src={phone}></img>
              <video autoPlay muted playsInline><source src={matchmobile}></source></video>
            </div>
          </div>
        }
      </section>
      <section className="info-app-pickus" id="about4" ref={sectionFour}>
            <div className="text">
              <h3 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>AI 시세 조회</h3>
              <h4 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>내 가전 시세가 궁금하시다구요?<br></br>그럼 한번 찍어봐요!</h4>
              <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={500}>우리집 가전 정보표/에너지효율표를 찍으면 AI가 시세 조회부터 견적신청까지 한번에 해드립니다.</p>
              <a href="https://repickus.com/estimate/esti_AI_guide.php" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={700}>서비스 이용하러가기</a>
            </div>
            {isDesktopOrMobile !== true ?
              <div className="phoneWrap">
                <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                  <img src={phone}></img>
                  <video autoPlay muted playsInline><source src={ai}></source></video>
                </div>
              </div>
            : 
               <div className="phoneWrap">
                <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                  <img src={phone}></img>
                  <video autoPlay muted playsInline><source src={ai}></source></video>
                </div>
              </div>
            }
      </section>
    </div>
  );
}

export default Service;
