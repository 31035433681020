import React, { useState, useEffect } from "react";
import AOS from "aos";
import "./style.css";
import { dehuvcontantsData } from "../components/data/dehuvcontantsData";
import { useMediaQuery } from 'react-responsive';
import Patent2020 from "../assets/2020_patent.jpg"
import award20161 from "../assets/2016_award1.jpg"
import award20162 from "../assets/2016_echothon1.jpg"
function Award() {
  useEffect(() => {
    AOS.init();
  })
  const isDesktopOrMobile = useMediaQuery({query: '(max-width:1200px)'});
  const dataAos = 'fade-up';
  const dataAosDuration = '700';
  const dataAosOffset = '80';
  return (
    <div id="company" className="company">
      <section className="title">
        <div className="center">
          <h2 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>환경을 위한 기업, 디휴브</h2>
          <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>생활밀착형 웨이스트 테크</p>
          <ul className="companyNav">
            <li>회사소개</li>
            <li className=""><a href="/Introduce">소개</a></li>
            <li className=""><a href="/History">연혁</a></li>
            <li className="on"><a href="/Award">수상</a></li>
          </ul>
        </div>
      </section>
      <section className="award companyBox">
        <div className="center">
            <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={500}>Our award details</p>
            <h3 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={700}>수상내역</h3>
            <div className="awardWrap">
              <h4 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>2020년</h4>
              <ul>
                <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                  <img src={Patent2020} data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} ></img>
                  <div className="textWrap">
                    <time data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>02월 19일</time>
                    <h5 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>특허증 등록</h5>
                    <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>재활용 물품 거래 시스템과 방법 및 이를 위한 컴퓨터 프로그램</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="awardWrap" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <h4 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>2016년</h4>
              <ul data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                  <img src={award20161} data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}></img>
                  <div className="textWrap">
                    <time data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>11월 29일</time>
                    <h5 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={500}>표창장 수상</h5>
                    <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={700}>창업활성화 및 일자리 창출 / 지역경제 발전에 기여</p>
                  </div>
                </li>
                <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                  <img src={award20162} data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}></img>
                  <div className="textWrap">
                    <time data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>10월 07일</time>
                    <h5 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={500}>2016 에코톤 수상</h5>
                    <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={700}>2016 환경 ICT 아이디어 공모전 우수상</p>
                  </div>
                </li>
              </ul>
            </div>
        </div>
      </section>
    </div>
  );
}

export default Award;