import React, { useState, useEffect } from "react";
import AOS from "aos";
import { useMediaQuery } from 'react-responsive';
import "./style.css";
import { dehuvcontantsData } from "../components/data/dehuvcontantsData";

function Introduce() {
  useEffect(() => {
    AOS.init();
  })
  const isDesktopOrMobile = useMediaQuery({query: '(max-width:1200px)'});
  const dataAos = 'fade-up';
  const dataAosDuration = '700';
  const dataAosOffset = '100';
  return (
    <div id="company" className="company">
      <section className="title">
        <div className="center">
          <h2 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>환경을 위한 기업, 디휴브</h2>
          <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>생활밀착형 웨이스트 테크</p>
          <ul className="companyNav">
            <li>회사소개</li>
            <li className="on"><a href="/Introduce">소개</a></li>
            <li className=""><a href="/History">연혁</a></li>
            <li className=""><a href="/Award">수상</a></li>
          </ul>
        </div>
      </section>
      <section className="introduce companyBox">
        <div className="center">
          <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={500}>Design Human Value</p>
          <h3 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={700}>디휴브는 고객의 가치 있는 제품을 디자인하여<p>가치 있는 행동을 이끌어냅니다.</p></h3>
          <div className="imgBox" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={900}></div>
          <div className="textBox">
            <h4 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>고객과 함께 같이 가치를 만들어 내는 <span>DEHUV</span>입니다.</h4>
            <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>기존의 재활용센터를 중심으로만 이루어져 왔던
              중고 가전/가구의 시장을 스마트하게 재해석 했습니다. 재활용 수거 업체들과 제휴하여 편리하게 
              이용하실 수 있도록 네트워크 연결을 하였습니다. 이로인해 웹, 앱을 이용하여
              지역의 제한 없이 파트너와 고객에 대한 정보와 중고 물품 및 서비스 정보등을 빠르게 유통하고
              고객과 파트너스 모두에게 경제적으로 win-win하는 시스템을 구축하였습니다.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Introduce;