import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import logo from "../assets/LOGO.png";

function Navbar() {
  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [navbarStyle, setNavbarStyle] = useState(false);
  const { pathname } = useLocation();
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const changeBackground = () => {
    if (window.scrollY > 0) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    if (pathname === "/") {
      setNavbarStyle('navbar-dark');
    } else {
      setNavbarStyle('navbar');
    }
  }, [pathname]);
  window.addEventListener("scroll", changeBackground);
  return (
    <>
      <nav className={navbar ? navbarStyle+" active" : navbarStyle}>
        <div className="content-components">
        <div className="content-inner navbar-contant">
        {/* LOGO */}
        <Link to="/" className="navbar-logo">
        <img className="logo" src={logo} />
        <div className="logo-text">
        <h3>DEHUV</h3>
        <p>Design Human Value</p>
        </div>
        </Link>

        {/* MENU ICON */}
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>

        {/* MENUS */}
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          {/* HOME */}
          <li className="nav-item">
            <Link to="/Introduce" className="nav-links" onClick={closeMobileMenu}>
              회사 소개
            </Link>
          </li>

          {/* SERVICE */}
          <li className="nav-item">
            <Link to="/Service" className="nav-links" onClick={closeMobileMenu}>
              서비스 소개
            </Link>
          </li>

          {/* Partnership */}
          <li className="nav-item">
            <Link to="/Partnership" className="nav-links" onClick={closeMobileMenu}>
              파트너스 소개
            </Link>
          </li>

          {/* CONTACT */}
          <li className="nav-item point">
            <a href="https://repickus.com/" className="nav-links" onClick={closeMobileMenu} target="_blank">
            피커스 바로가기
            </a>
          </li>
        </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
