import React, { useState, useEffect } from "react";
import AOS from "aos";
import "./style.css";
import pickusvideo from "../assets/pickus_video.mp4";
import { dehuvcontantsData } from "../components/data/dehuvcontantsData";
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
function Main() {
  useEffect(() => {
    AOS.init();
  })
  const isDesktopOrMobile = useMediaQuery({query: '(max-width:1200px)'});
  const dataAos = 'fade-up';
  const dataAosDuration = '700';
  const dataAosOffset = '200';
  SwiperCore.use([ EffectFade, Autoplay, Pagination, Navigation ])
  return (
    <div id="main">
        <Swiper
          slidesPerView={1}
          pagination={{
            type: "fraction",
          }}
          navigation={true}
          loop={true}
          effect={"fade"}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Navigation]}
          className="slideBox"
          id="slide"
        >
          <SwiperSlide className="one">
            <div className="textWrap">
              <h3><span>Provide Solutions</span> that meet client needs</h3>
              <p>고객이 원하는 최적의 솔루션을 제공합니다.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="two">
            <div className="textWrap">
              <h3>Spreading <span>eco-friendly</span> consumer culture</h3>
              <p>친환경 소비문화 확산을 위한 새로운 패러다임을 제시합니다.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="three">
            <div className="textWrap">
              <h3><span>Constant</span> thought and research</h3>
              <p>소비자들에게 필요한 모든 가치를 제공하기 위해 끊임없이 고민하고 연구합니다.</p>
            </div>
          </SwiperSlide>
        </Swiper>
        <section className="valueBox">
            <div className="center">
                <h2 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>디휴브의 가치</h2>
                <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>디휴브는 낡은 것을 새롭게 보는 관점으로 더 나은 경제적 가치를 만듭니다.</p>
                {isDesktopOrMobile !== true ?
                    <ul className="valueList">
                        <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                            <div className="bg"><p>고객이 사용하지 않는 물건을 판매하거나 필요한 물건을 구입할 수 있는 스마트하고 간편한 서비스를 제공함으로써 환경보호에도 앞장서고 있습니다.</p></div>
                            <h3>웨이스트 테크</h3>
                        </li>
                        <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>
                          <div className="bg"><p>중고 가전/가구를 보다 쉽고 간편하게 구매하고 판매할 수 있는 친환경 소비문화를 확산하기 위한 새로운 패러다임을 제시합니다.</p></div>
                            <h3>자연주의</h3>
                        </li>
                        <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={500}>
                          <div className="bg"><p>고객과 업체의 마찰과 불안감을 최소화 하기위해 자체적인 매뉴얼을 구축하여 운영하고 있습니다.</p></div>
                            <h3>안전성</h3>
                        </li>
                        <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={700}>
                          <div className="bg"><p>전국의 재활용품 수거 업체와 제휴하여 고객과 업체의 접근성을 높여주며 최적의 솔루션을 제공합니다.</p></div>
                            <h3>파트너십</h3>
                        </li>
                    </ul>
                    : 
                    <Swiper
                    navigation={true}
                    loop={true}
                    modules={[Navigation]}
                      className="valueSlide"
                      data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}
                    >
                      <SwiperSlide className="waste">
                        <div className="bg"><p>고객이 사용하지 않는 물건을 판매하거나 필요한 물건을 구입할 수 있는 스마트하고 간편한 서비스를 제공함으로써 환경보호에도 앞장서고 있습니다.</p></div>
                        <h3>웨이스트 테크</h3>
                      </SwiperSlide>
                      <SwiperSlide className="nature">
                        <div className="bg"><p>중고 가전/가구를 보다 쉽고 간편하게 구매하고 판매할 수 있는 친환경 소비문화를 확산하기 위한 새로운 패러다임을 제시합니다.</p></div>
                        <h3>자연주의</h3>
                      </SwiperSlide>
                      <SwiperSlide className="safe">
                        <div className="bg"><p>고객과 업체의 마찰과 불안감을 최소화 하기위해 자체적인 매뉴얼을 구축하여 운영하고 있습니다.</p></div>
                        <h3>안전성</h3>
                      </SwiperSlide>
                      <SwiperSlide className="partner">
                        <div className="bg"><p>전국의 재활용품 수거 업체와 제휴하여 고객과 업체의 접근성을 높여주며 최적의 솔루션을 제공합니다.</p></div>
                        <h3>파트너십</h3>
                      </SwiperSlide>
                    </Swiper>
                }
            </div>
        </section>
        <section className="serviceBox">
            <div className="center">
                <h2 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>운영 서비스</h2>
                <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>환경을 위한 디휴브의 기술</p>
                {isDesktopOrMobile !== true ?
                    <ul className="serviceList">
                        <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>
                            <div class="img"></div>
                            <div class="bg">
                              <p>쉽고 빠르게 고객님들의 중고 가전/가구의 가격을 비교 견적 받고 원하는 견적을 통해 판매를 결정할 수 있습니다.</p>
                              <a href="">서비스 이용하기</a>
                            </div>
                            <h3>중고판매</h3>
                            <p>BEST</p>
                        </li>
                        <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={500}>
                            <div class="img"></div>
                            <div class="bg">
                              <p>AI를 통한 정보인식으로 시세조회부터 견적신청까지 한번에 가능합니다.</p>
                              <a href="">서비스 이용하기</a>
                            </div>
                            <h3>AI 시세조회</h3>
                            <p>NEW</p>
                        </li>
                        <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={700}>
                            <div class="img"></div>
                            <div class="bg">
                              <p>필요한 상품에 대해 작성하면 파트너가 직접 상품 제안을 해드리며 마음에 든다면 바로 구매가 가능합니다.</p>
                              <a href="">서비스 이용하기</a>
                            </div>
                            <h3>구매매칭</h3>
                        </li>
                        <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={900}>
                            <div class="img"></div>
                            <div class="bg">
                              <p>피커스 파트너가 직접 등록한 A/S 보장이 되는 합리적인 가격의 중고 가전/가구를 만나보세요.</p>
                              <a href="">서비스 이용하기</a>
                            </div>
                            <h3>파트너샵</h3>
                        </li>
                        <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={1200}>
                            <div class="img"></div>
                            <div class="bg">
                              <p>사무 이사/정리시 철거부터 원상복구까지 한번에 쉽고 빠른 전문가의 견적을 받으실 수 있습니다.</p>
                              <a href="">서비스 이용하기</a>
                            </div>
                            <h3>철거/원상복구</h3>
                        </li>
                    </ul>
                    :
                    <Swiper
                    navigation={true}
                    loop={true}
                    modules={[Navigation]}
                      className="serviceSlide"
                      data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}
                    >
                      <SwiperSlide className="sell">
                        <div class="img"></div>
                        <div class="bg">
                          <p>쉽고 빠르게 고객님들의 중고 가전/가구의 가격을 비교 견적 받고 원하는 견적을 통해 판매를 결정할 수 있습니다.</p>
                          <a href="">서비스 이용하기</a>
                        </div>
                        <h3>중고판매</h3>
                      </SwiperSlide>
                      <SwiperSlide className="ai">
                        <div class="img"></div>
                        <div class="bg">
                          <p>AI를 통한 정보인식으로 시세조회부터 견적신청까지 한번에 가능합니다.</p>
                          <a href="">서비스 이용하기</a>
                        </div>
                        <h3>AI 시세조회</h3>
                      </SwiperSlide>
                      <SwiperSlide className="buy">
                        <div class="img"></div>
                        <div class="bg">
                          <p>필요한 상품에 대해 작성하면 파트너가 직접 상품 제안을 해드리며 마음에 든다면 바로 구매가 가능합니다.</p>
                          <a href="">서비스 이용하기</a>
                        </div>
                        <h3>구매매칭</h3>
                      </SwiperSlide>
                      <SwiperSlide className="shop">
                        <div class="img"></div>
                        <div class="bg">
                          <p>피커스 파트너가 직접 등록한 A/S 보장이 되는 합리적인 가격의 중고 가전/가구를 만나보세요.</p>
                          <a href="">서비스 이용하기</a>
                        </div>
                        <h3>파트너샵</h3>
                      </SwiperSlide>
                      <SwiperSlide className="demotion">
                        <div class="img"></div>
                        <div class="bg">
                          <p>사무 이사/정리시 철거부터 원상복구까지 한번에 쉽고 빠른 전문가의 견적을 받으실 수 있습니다.</p>
                          <a href="">서비스 이용하기</a>
                        </div>
                        <h3>철거/원상복구</h3>
                      </SwiperSlide>
                    </Swiper>
                }
                <video autoPlay loop muted playsInline controls data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><source src={pickusvideo}></source></video>
            </div>
        </section>
        <section className="partnershipBox">
            <div className="center">
                <div className="leftBox">
                    <h2 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>자원순환과 환경 보호를 위한 첫 걸음, <br></br>디휴브와 함께 만들어가세요.</h2>
                    <a href="/Introduce" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>더 알아보기 +</a>
                </div>
                <div className="rightBox">
                    <div className="member" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                        <p>전체 회원 수</p>
                        <h3>11,588명 +</h3>
                    </div>
                    <div className="visit" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                        <p>월 방문자 수</p>
                        <h3>32,588명 +</h3>
                    </div>
                    <div className="partner" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                        <p>파트너 센터 수</p>
                        <h3>581개 +</h3>
                    </div>
                </div>
            </div>
        </section>
    </div>
  );
}

export default Main;
