import React, { useState, useEffect } from "react";
import AOS from "aos";
import "./style.css";
import { dehuvcontantsData } from "../components/data/dehuvcontantsData";
import { useMediaQuery } from 'react-responsive';
function History() {
  useEffect(() => {
    AOS.init();
  })
  const isDesktopOrMobile = useMediaQuery({query: '(max-width:1200px)'});
  const dataAos = 'fade-up';
  const dataAosDuration = '700';
  const dataAosOffset = '100';
  return (
    <div id="company" className="company">
      <section className="title">
        <div className="center">
          <h2 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>환경을 위한 기업, 디휴브</h2>
          <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>생활밀착형 웨이스트 테크</p>
          <ul className="companyNav">
            <li>회사소개</li>
            <li className=""><a href="/Introduce">소개</a></li>
            <li className="on"><a href="/History">연혁</a></li>
            <li className=""><a href="/Award">수상</a></li>
          </ul>
        </div>
      </section>
      <section className="companyBox history">
        <div className="center">
          <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={500}>Our history</p>
          <h3 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={700}>회사 연혁</h3>
          <ul className="timeLine">
            <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <h5>2022</h5>
              <h4>삼성 닷컴 중고보상 프로그램 협약</h4>
              <ul>
                <li>LG CNS 라이프 케어 협약</li>
                <li>빅데이터 활용 지원사업</li>
                <li>고양 ICT 기술 개발 지원 사업</li>
                <li>지역특화육성 지원사업</li>
                <li>스마트 서비스 지원사업</li>
                <li>AI+ 콘텐츠 지원사업</li>
              </ul>
            </li>
            <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <h5>2021</h5>
              <h4>고양 ICT 기술 개발 지원사업</h4>
              <ul>
                <li>빅데이터 활용 지원사업</li>
                <li>중소벤처기업부 창업성장기술개발 R&D</li>
              </ul>
            </li>
            <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <h5>2020</h5>
              <h4>데이터 바우처 지원사업</h4>
              <ul>
                <li>고양 ICT 마케팅 지원사업</li>
                <li>디자인 솔루션 랩 프로젝트</li>
                <li>기술 닥터 지원사업</li>
              </ul>
            </li>
            <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <h5>2019</h5>
              <h4>창업도약 패키지 IT콘텐츠 시장최적화 프로그램</h4>
              <ul>
                <li>특허등록 "재활용 물품 거래 시스템과 방법 및 이를 위한 컴퓨터 프로그램"</li>
                <li>R&D 플러스 파트너 지원프로그램</li>
                <li>청년 프런티어 지원사업</li>
              </ul>
            </li>
            <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <h5>2018</h5>
              <h4>창업성공지원 프로그램</h4>
              <ul>
                <li>창업도약 패키지 성장촉진 프로그램</li>
                <li>중소기업 R&D 기획지원사업 전략과제</li>
                <li>자인 솔루션 랩 프로젝트</li>
              </ul>
            </li>
            <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <h5>2017</h5>
              <h4>청년창업 smart2030</h4>
              <ul>
                <li>G창업 프로젝트</li>
                <li>재도전 성공패키지 지원사업</li>
                <li>앱쇼 코리아 전시회 참가</li>
              </ul>
            </li>
            <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
              <h5>2016</h5>
              <h4>환경부 ICT 공모전 산업기술원장상 수상</h4>
              <ul>
                <li>경기중소기업 종합지원센터 이사상 수상</li>
                <li>청년창업 smart2030</li>
                <li>G창업 프로젝트</li>
                <li>창의도전형 SW개발 지원사업</li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}
export default History;