import React, { useState, useEffect, useRef } from "react";
import AOS from "aos";
import "./style.css";
import { dehuvcontantsData } from "../components/data/dehuvcontantsData";
import { useMediaQuery } from 'react-responsive';
import buyicon from "../assets/icon-Buying.png";
import demolition from "../assets/icon-Demolition.png";
import truck from "../assets/icon-Truck2.png";
import one from "../assets/onef.png";
import two from "../assets/twop.png";
import three from "../assets/threep.png";
import servicemap from "../assets/servicemap.png";
function Partnership() {
    useEffect(() => {
        AOS.init();
      })
    const isDesktopOrMobile = useMediaQuery({query: '(max-width:1200px)'});
    const dataAos = 'fade-up';
    const dataAosDuration = '700';
    const dataAosOffset = '100';
    const sectionOne = useRef(null);
    const sectionTwo = useRef(null);
    const sectionThree = useRef(null);
    const scrollToSection = (
    elementRef: React.MutableRefObject<HTMLButtonElement | null>,
    ) => {
    if (elementRef.current !== null) {
        const offset = elementRef.current.offsetTop;
        window.scrollTo({
        top: offset - 70,
        behavior: 'smooth',
        });
    }
    };
  return (
    <div id="company" className="partnership">
      <section className="title">
        <div className="center">
          <h2 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>고객과 업체의 소통 창구 역할, 디휴브</h2>
          <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>재활용품 사장님들의 고민해결사</p>
          <ul className="companyNav">
            <li>제휴소개</li>
            <li className="on" onClick={() => scrollToSection(sectionOne)}>파트너스</li>
            <li className="" onClick={() => scrollToSection(sectionTwo)}>서비스</li>
            <li className="" onClick={() => scrollToSection(sectionThree)}>제휴가입</li>
          </ul>
        </div>
      </section>
      <section className="partnershipBox companyBox">
        <div className="center">
            <p data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={500}>Cooperative relationship</p>
            <h3 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={700}>고객들에겐 편리함을, 우리 센터엔 수익 창출의 기회를!</h3>
            <div className="partners" ref={sectionOne}>
                <h4 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={900}>파트너는 어떤 일을 하게 되나요?</h4>
                <ul>
                    <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                        <h5>중고매입</h5>
                        <div className="imgWrap">
                            <img src={truck}></img>
                        </div>
                        <p>매입 신청내역에 비용을 지불하고 고객과 날짜 조율 후 직접 운반해옵니다.</p>
                    </li>
                    <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={300}>
                        <h5>중고판매</h5>
                        <div className="imgWrap">
                            <img src={buyicon}></img>
                        </div>
                        <p>마켓에 물품을 등록하거나 고객이 원하는 물품을 제시하고 판매합니다.</p>
                    </li>
                    <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={600}>
                        <h5>철거원복</h5>
                        <div className="imgWrap">
                            <img src={demolition}></img>
                        </div>
                        <p>고객이 원하는 철거 및 원상복구 서비스를 제공합니다.</p>
                    </li>
                </ul>
            </div>
            <div className="tiebanner tie1" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                <div className="bg"></div>
                <h4>고객과 쉽게 만날 수 있는 방법</h4>
                <p>피커스는 언제나 빠르게 원하는 고객을 만날 수 있습니다.</p>
            </div>
            <div className="service" ref={sectionTwo}>
                <h4 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>파트너에게 제공되는 서비스는 무엇인가요?</h4>
                <ul>
                    <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>
                        <h5>마케팅 지원</h5>
                        <p>피커스를 통한 매입/판매가 잘 될 수 있도록 지속적인 마케팅 지원을 약속합니다.</p>
                    </li>
                    <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={200}>
                        <h5>업체 전용 서비스</h5>
                        <p>고객 관리 및 스케줄 관리/빠른 고객 문의 및 추천을 통해 편리한 견적진행을 도와드립니다.</p>
                    </li>
                    <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={400}>
                        <h5>온라인 마켓</h5>
                        <p>쉬운 물품 등록부터 매칭을 통한 판매까지! 쉽고 빠른 고객 매칭을 도와드립니다.</p>
                    </li>
                    <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={600}>
                        <h5>센터간 정보유통</h5>
                        <p>재활용 센터간의 네트워크 구축을 도와드립니다.</p>
                    </li>
                </ul>
                <img src={servicemap} data-aos="zoom-in" data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset} data-aos-delay={1100}></img>
            </div>
            <div className="enjoy" ref={sectionThree}>
                <h4 data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>전국에서 함께 일할 업체 사장님을 모십니다!</h4>
                <ul>
                    <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>업체 정보 및 사업자 등록 확인</li>
                    <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>활동 지역 및 맞춤 견적 설정
                    <span>활동지역과 맞춤 견적 내용을 기반으로 등록된 견적을 추천해드리고 고객님을 알선해드립니다.</span>
                    </li>
                    <li data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>업체 가입 승인 후 활동</li>
                </ul>
                <a href="https://repickus.com/bbs/register_form.php?registerType=partner" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}>피커스 파트너스 가입</a>
            </div>
        </div>
      </section>
    </div>
  );
}

export default Partnership;